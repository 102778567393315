import * as React from 'react';
import createDefaultLayout from '../layouts/defaultLayout';
import { GatsbyPageProps } from '../types';

export default class NotFoundPage extends React.Component<GatsbyPageProps, {}> {
    render() {
        const Layout = createDefaultLayout();
        return (
            <Layout location={this.props.location}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '80vh',
                        flexDirection: 'column',
                    }}
                >
                    <p>Oops! This isn't a page!</p>
                </div>
            </Layout>
        );
    }
}
