import * as React from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import Helmet from 'react-helmet';
import { Layout, Icon, Tooltip, notification, Button } from 'antd';
const { Content } = Layout;
import { GatsbyLayoutProps } from '../types';
import { getHoverableScaleContainer } from '../util/animation';

const HoverableScaleContainer = getHoverableScaleContainer(1.2);

//Interface for State
interface DefaultLayoutState {
    
}

const menuIconStyle = {
    fontSize: 50,
    cursor: 'pointer',
    paddingLeft: 10,
    paddingTop: 10,
};

export default function createDefaultLayout() {
    return class PageLayout extends React.PureComponent<
        GatsbyLayoutProps,
        DefaultLayoutState
    > {
        constructor(props: GatsbyLayoutProps) {
            super(props);
            this.state = {};
        }

        render() {
            const { children, location } = this.props;
            return (
                <StaticQuery
                    query={graphql`
                        query SiteTitleQuery {
                            site {
                                siteMetadata {
                                    title
                                }
                            }
                        }
                    `}
                    render={data => (
                        <Layout style={{ height: '100vh' }}>
                            <Helmet
                                title={data.site.siteMetadata.title}
                                meta={[
                                    {
                                        name: 'description',
                                        content:
                                            "Tele-Testing - tele-health testing for everyone",
                                    },
                                    {
                                        name: 'keywords',
                                        content:
                                            'Teletesting, Telehealth, Health, Testing, Speech, Pathology',
                                    },
                                ]}
                            >
                                <html lang="en" />
                            </Helmet>
                            <Content>
                                {children}
                            </Content>
                        </Layout>
                    )}
                />
            );
        }
    };
}
