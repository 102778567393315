import posed from 'react-pose';

export const getHoverableScaleContainer = (scaleFactor: number) => {
    return posed.div({
        hoverable: true,
        init: {
            scale: 1,
        },
        hover: {
            scale: scaleFactor,
        },
    });
};

export const getPressableScaleContainer = (scaleFactor: number) => {
    return posed.div({
        pressable: true,
        transition: { type: 'tween', ease: 'easeIn' },
        init: {
            scale: 1,
        },
        press: {
            scale: scaleFactor,
        },
    });
};
